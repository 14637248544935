import type { CardMediaAssetProps, CardCommonContainerProps } from './CardAsset';
import type { CardContentProps } from './CardContentA';
import type { CardContentEProps } from './CardContentE';
import type { UrlObject } from 'url';
import type { TypographyProps } from '@mui/material';

import * as React from 'react';
import { useEditorHandlers } from './@editor';
import styled from '@emotion/styled';
import { Card as MuiCard } from '@mui/material';
import CardActionArea from '@mui/material/CardActionArea';
import { Link } from '../Link';
import { CardAsset } from './CardAsset';
import { CardContentA } from './CardContentA';
import { CardContentB } from './CardContentB';
import { CardContentC } from './CardContentC';
import { CardContentE } from './CardContentE';
import { useAppContext } from '../../contexts/AppContext';
import type { CardTypeEnum } from '../../types/enum';

export interface CardProps extends CardMediaAssetProps, CardContentProps, CardContentEProps {
  href?: UrlObject | string;
  alt: string;
  src: string;
  width: number;
  height: number;
  hoverEffect?: boolean;
  template: CardTypeEnum;
  typographyOptions?: TypographyProps;
  sx?: object;
  className?: string;
}

const CardStyled = styled(MuiCard)(({ theme, template, hoverEffect }: any) => ({
  width: '100%',
  height: '100%',
  backgroundColor: theme.theme_component_card_background_color || theme.palette.background.paper,
  boxShadow: theme.theme_component_card_box_shadow || theme.shadows[3],
  borderRadius: template === 'big' ? 0 : theme.theme_component_card_border_radius || theme.shape.borderRadius,
  transition: `transform ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeInOut}`,
  '&:hover': hoverEffect && {
    transform: 'scale3d(1.05, 1.05, 1)',
  },
}));

const Inner = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

export const Card = (props: CardProps) => {
  const {
    href,
    src,
    width,
    height,
    alt,
    template,
    title,
    description,
    className,
    sx,
    typographyOptions,
    hoverEffect,
    ...other
  } = props;
  const appContext = useAppContext();
  const { componentOptions } = appContext.useConfig();

  const x = width || componentOptions.component_card_width || 100;
  const y = height || componentOptions.component_card_height || 100;

  const CommonCardContainer = ({ children }: CardCommonContainerProps) => {
    return (
      <CardStyled
        elevation={0}
        className={className}
        sx={sx}
        hoverEffect={hoverEffect}
        template={template}
        {...useEditorHandlers('Card')}
      >
        <CardActionArea component={Link} href={href || '/'} sx={{ height: '100%' }}>
          <Inner>
            <CardAsset src={src} width={x} height={y} alt={alt} {...other} />
            {children}
          </Inner>
        </CardActionArea>
      </CardStyled>
    );
  };

  return (
    <>
      {
        {
          default: (
            <CommonCardContainer>
              <CardContentA title={title} {...typographyOptions} />
            </CommonCardContainer>
          ),
          simple: (
            <CommonCardContainer>
              <CardContentB title={title} {...typographyOptions} />
            </CommonCardContainer>
          ),
          extensive: (
            <CommonCardContainer>
              <CardContentC description={description} {...typographyOptions} />
            </CommonCardContainer>
          ),
          big: (
            <CommonCardContainer>
              <></>
            </CommonCardContainer>
          ),
          partial: <CardContentE width={width} height={height} {...other}></CardContentE>,
        }[template || 'default']
      }
    </>
  );
};
