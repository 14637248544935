import type { ImageLoaderWrapperProps } from '../ImageLoaderWrapper';

import * as React from 'react';
import styled from '@emotion/styled';
import { ImageLoaderWrapper } from '../ImageLoaderWrapper';
import CardMedia from '@mui/material/CardMedia';

export interface CardMediaAssetProps extends ImageLoaderWrapperProps {
  alt: string;
  className?: string;
}
export type CardCommonContainerProps = {
  children: JSX.Element,
};

const CardMediaStyled = styled(CardMedia)(({ theme }) => ({
  width: '100%',
  height: 'auto',
  backgroundColor: theme.palette.grey['900'],
  pointerEvents: 'none',
  marginBottom: 'auto',
  '& span:first-of-type': {
    display: 'block !important',
  },
})) as typeof CardMedia;

export const CardAsset = (props: CardMediaAssetProps) => {
  const { width, height, className, ...other } = props;

  return (
    <CardMediaStyled className={className}>
      <div
        style={{
          display: 'block',
          position: 'relative',
          aspectRatio: `${width / height}`,
        }}
      >
        <ImageLoaderWrapper width={width} height={height} {...other} />
      </div>
    </CardMediaStyled>
  );
};
